<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            {{new Date().getFullYear()}} &copy; I-ON Communications.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>
