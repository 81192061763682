export const menuItems = [
    {
        id: 100,
        label: 'menu.dashboards',
        isTitle: true,
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 110,
        label: 'menu.dashboard',
        icon: 'mdi-monitor-dashboard',
        link: '/dashboard',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 200,
        label: 'menu.manage',
        isTitle: true,
        authorization: ['ADMIN', 'HQ']
    },
    {
        id: 210,
        label: 'menu.managerAccount',
        icon: 'mdi-account-check-outline',
        link: '/manager/list',
        authorization: ['ADMIN', 'HQ']
    },
    {
        id: 220,
        label: 'menu.headquarters',
        icon: 'mdi-home-circle-outline',
        link: '/hq/list',
        authorization: ['ADMIN', 'HQ']
    },
    {
        id: 300,
        label: 'menu.charge',
        isTitle: true,
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 310,
        label: 'menu.chargePlace',
        icon: 'mdi-office-building-outline',
        link: '/charge/place/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 320,
        label: 'menu.chargePoint',
        icon: 'mdi-ev-station',
        link: '/charge/point/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 330,
        label: 'menu.chargePrice',
        icon: 'mdi-cash',
        link: '/charge/price/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 340,
        label: 'menu.chargeOutput',
        icon: 'mdi-cog-transfer-outline',
        link: '/charge/output/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 350,
        label: 'menu.plan',
        icon: 'mdi-calendar-account-outline',
        authorization: ['ADMIN', 'HQ', 'CP'],
        subItems: [
            {
                id: 351,
                label: 'menu.memberPlan',
                link: '/plan/member/list',
                authorization: ['ADMIN'],
                parentId: 350
            },
            {
                id: 352,
                label: 'menu.businessPlan',
                link: '/plan/business/list',
                authorization: ['ADMIN', 'HQ', 'CP'],
                parentId: 350
            }
        ]
    },
    {
        id: 360,
        label: 'menu.chargeHistory',
        icon: 'mdi-credit-card-clock-outline',
        link: '/charge/history/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 370,
        label: 'menu.planHistory',
        icon: 'mdi-calendar-clock',
        link: '/plan/history/list',
        authorization: ['ADMIN']
    },
    {
        id: 400,
        label: 'menu.settlement',
        isTitle: true,
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 410,
        label: 'menu.manageSettlement',
        icon: 'mdi-progress-question',
        link: '/settlement/list',
        authorization: ['ADMIN', 'HQ', 'CP']
        /*subItems: [
            {
                id: 411,
                label: 'menu.memberPlan',
                link: '/settlement/list',
                parentId: 410
            },
            {
                id: 412,
                label: 'menu.businessPlan',
                link: '/settlement/list',
                parentId: 410
            }
        ]*/
    },
    {
        id: 420,
        label: 'menu.managePoint',
        icon: 'mdi-file-powerpoint-box-outline',
        link: '/point/list',
        authorization: ['ADMIN', 'HQ', 'CP']
    },
    {
        id: 500,
        label: 'menu.app',
        isTitle: true,
        authorization: ['ADMIN', 'HQ']
    },
    {
        id: 510,
        label: 'menu.manageUser',
        icon: 'mdi-account-multiple',
        link: '/app/member/list',
        authorization: ['ADMIN']
    },
    {
        id: 520,
        label: 'menu.manageAd',
        icon: 'mdi-television',
        link: '/app/ad/list',
        authorization: ['ADMIN', 'HQ']
    },
    {
        id: 900,
        label: 'menu.system',
        isTitle: true,
        authorization: ['ADMIN']
    },
    {
        id: 910,
        label: 'menu.notice',
        icon: 'mdi-information-outline',
        link: '/system/notice/list',
        authorization: ['ADMIN']
    },
    {
        id: 920,
        label: 'menu.termsPolicy',
        icon: 'mdi-file-document-edit-outline',
        link: '/system/terms/list',
        authorization: ['ADMIN']
    },
    {
        id: 930,
        label: 'menu.systemNotification',
        icon: 'mdi-post-outline',
        link: '/system/notification/list',
        authorization: ['ADMIN']
    }
];
